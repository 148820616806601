<template>
  <div class="container">
    <div v-if="showContinuePlaceHolder">
      <continueWatchingPlaceholder></continueWatchingPlaceholder>
    </div>
    <div v-else-if=" !showContinuePlaceHolder && continueWatchData && continueWatchData.length > 0">
      <section>
        <div id="continueWatch-controls" class="slider-controls continue-slider-fixes" :dir="localDisplayLang === 'mr' ? 'ltr' : ''" > 
              <button type="button">
                <img class="color-filler" src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
              </button>
              <button type="button">
                <img class="color-filler" src="@/assets/icons/more-than-filled-50.png" width="30px" alt="more" />
              </button>
          </div>
        <div
          class="slider-header"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        >
          <div class="slider-title">
            <span class="title" :title="$t('Continue Watching')">{{ $t("Continue Watching") }}</span>
          </div>

          <router-link 
            class="continue-slider-see-all"
            :to="seeAll()">
            <span  v-if="continueWatchData.length >= 5"
            class="seeall">{{ $t("More") }}</span>
            <div> 
              <img src="@/assets/icons/more-than-filled-50.png" alt="more" class="seeall-arrow" /> 
            </div>
          </router-link>
      

          <div class="slider-head">
            <div class="slider-head-btns">
         
            </div>
          </div>
        </div>
        <div
          class="continueWatch-slider-content"
          id="continueWatch-carousel"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        >
          <div
            v-for="(item, index) in continueData"
            :key="index"
            tabindex="-1"
            class="categorised-continue-contents"
          >
            <div class="continueWatch-box">
              <div class="card-contain">
                <continueMerge
                  :content="item"
                  :screen="screen"
                  :playerInstance="playerInstance"
                  @removeFromContinueData="removeDataFromFire"
                  :isContinueWatch="true"
                />
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import { store } from "@/store/store";
import {
  vLivePlayer,
  vLivePlaylistItem,
} from "/src/vue-player/vLiveWebPlayer.js";

export default {
  props: {
    screen: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    }
  },
  data() {
    return {
      continueWatchContent: [],
      continueWatchData: [],
      slider: null,
      showContinuePlaceHolder: false,
      detailPlaylist: [],
      finalPlaylist: [],
      availabilities: [],
      subscriptions: [],
      purchases: [],
      contentProgress: null,
      completeEpisodeListing: [],
      playlistIndex: 0,
      continueData: [],
      page: 1,
      isMpegRequired: false,
      totalFirebaseResponse: null,
    };
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
    ]),
    fetchCardType() {
      return {
        cardName: "Slider",
        type: "LANDSCAPE",
        height: "147px",
        width: "261px",
        playButton: { enablePlay: false },
      };
    },
  },
  created() {
    //assigning subscription list response.
    this.isMpegRequired = this.checkMpegRequired();
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();

      setTimeout(() => {
        if (
          this.localDisplayLang === "ara" &&
          this.continueWatchData &&
          this.continueWatchData.length < 5
        ) {
          document.getElementById("continueWatch-carousel-iw")
            ? (document.getElementById(
                "continueWatch-carousel-iw"
              ).style.textAlign = "-webkit-right")
            : "";
        }
      }, 700);
    });
  },
  updated() {
    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  mounted() {
    //To get the availability list.
    setTimeout(() => {
      if (this.availabilityList !== undefined) {
        this.availabilities = this.availabilityList;
      }
    }, 2000);
    eventBus.$on("removeFromContinueWatch", (data) => {
      this.removeDataFromFire(data);
    });

    //To fetch continue watching on app launch.
    this.checkEpisodeContinueWatch();

    //To check for newly added firebase data.
    eventBus.$on("check-new-fire-data", () => {
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();
    });

    eventBus.$on("check-for-series-route", () => {
      this.checkEpisodeContinueWatch();
    });
  },
  methods: {
    ...mapMutations(["setContinueWatchData"]),
    ...mapActions(["listContent"]),
    seeAll() {

    let currentLanguage = this.getCurrentLanguageUrlBase();

      // this.$router.push({
      //   name: "seeAllContinueWatch",
      //   params: { continueWatchData: this.totalFirebaseResponse, lang: currentLanguage },
      // });
      return ({
        name: "seeAllContinueWatch",
        params: { continueWatchData: this.totalFirebaseResponse, lang: currentLanguage },
      });
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    attachFirebaseNodes(content) {
      let index = this.continueData.findIndex((el) => {
        return el.objectid === content.objectid;
      });

      if (index > -1) {
        content.firebaseKeys = this.continueData[index];
      }
    },

    constructSlider() {
      if (this.continueWatchData && this.continueWatchData.length > 0) {
        let data = {
          container: "#continueWatch-carousel",
          controlsContainer: "#continueWatch-controls",
          nav: false,
          loop: false,
          slideBy: "page",
          // autoWidth: true,
          gutter: 5,
          // items: 5,
          fixedWidth: 270,
          // responsive: {
          //   360: {
          //     items: 2,
          //     edgePadding: 5,
          //     gutter: 5,
          //   },
          //   576: {
          //     edgePadding: 5,
          //     gutter: 5,
          //     items: 2,
          //   },
          //   768: {
          //     items: 3,
          //     edgePadding: 5,
          //     gutter: 15,
          //   },
          //   900: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 4,
          //   },
          //   1024: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 4,
          //   },
          //   1200: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 4,
          //   },
          //   1280: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 4,
          //   },
          //   1366: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 5,
          //   },
          //   1440: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 5,
          //   },
          //   1600: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 5,
          //   },
          //   1680: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 6,
          //   },
          //   1920: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 6,
          //   },
          //   2200: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 7,
          //   },
          //   2400: {
          //     edgePadding: 5,
          //     gutter: 15,
          //     items: 8,
          //   },
          // },
        };
        this.$nextTick(() => {
          if (!document.getElementById("continueWatch-carousel")) return;
          this.slider = tns(data);

          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        });
      }
    },
    removeDataFromFire(data) {
      this.showContinuePlaceHolder = true;
      this.removeFromContinueWatch(data);
      // this.slider = null;
      setTimeout(() => {
        this.checkEpisodeContinueWatch();
      }, 1000);
    },
    checkEpisodeContinueWatch() {
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "",
        response = null,
        episodeObjs = null,
        newEpisodeObj = null,
        segEpisodeArr = null,
        movieObjs = null;

      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
        firebase
          .database()
          .ref(path)
          .once("value")
          .then((snapshot) => {
            response = this.collectionsToArray(snapshot.val());
            //this.totalFirebaseResponse = response

            // Filter the movies for continue watch
            movieObjs = response.filter((element) => {
              return (
                element.category === "MOVIE" &&
                element.status === "INPROGRESS" &&
                element.watchedduration
              );
            });
            // Filter only the series with episodes key
            episodeObjs = response.filter((element) => {
              return element.episodes;
            });

            // Convert the collection to an array
            newEpisodeObj = [...this.collectionsToArray(episodeObjs)];

            // Create an array of only the episodes of the particular series
            newEpisodeObj.forEach((element) => {
              segEpisodeArr = this.collectionsToArray(element.episodes);
              //assign series title to each episode
              segEpisodeArr.forEach((el) => {
                el.title = element.title;
              });

              // Sort episodes based on timestamp
              segEpisodeArr = segEpisodeArr.sort((a, b) => {
                return b.updatedat - a.updatedat;
              });

              // Pick the first index after sorting as it will be the latest episode watched
              this.continueWatchContent.push(segEpisodeArr[0]);
            });
            this.continueWatchDataTemp = [
              ...this.continueWatchContent,
              ...movieObjs,
            ];

            this.continueWatchDataTemp = this.continueWatchDataTemp.filter(
              (item) => {
                return item.status ==="INPROGRESS" && item.watchedduration;
              }
            );
            this.continueWatchDataTemp = this.continueWatchDataTemp.sort(
              (a, b) => {
                return b.updatedat - a.updatedat;
              }
            );
            this.totalFirebaseResponse = this.continueWatchDataTemp;
            this.continueWatchDataTemp =
              this.continueWatchDataTemp.length > 15
                ? this.continueWatchDataTemp.slice(0, 15)
                : this.continueWatchDataTemp;
								try{
										this.checkLanguage();
									}
								catch(error){
								console.log(error);
								}
          });
      }
    },

    checkLanguage() {
      const seriesObjIds = [];
      const moviesObjIds = [];

      this.continueWatchDataTemp.forEach((element) => {
        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } else if (element.category == "MOVIE") {
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });

      let seriesPromise;
      let moviesPromise;

      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
        };

        seriesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                    seriesname: data.seriesname,
                  };
                }
              });
            });

            resolve(this.continueWatchDataTemp);
          });
        });
      }

      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
        };


        moviesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                  };
                }
              });
            });

            resolve(this.continueWatchDataTemp);
          });
        });
      }

      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {

        this.continueWatchData = finalData[1] ? finalData[1] : finalData[0];

        if (this.continueWatchData && this.continueWatchData.length > 0) {
          this.continueWatchData = this.continueWatchData.filter((el) => {
            return el.status === "INPROGRESS";
         });

          this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }

        this.showContinuePlaceHolder = false;
        this.constructSlider();
      });
    },
  },
  beforeDestroy() {
    if(this.playerInstance) {
      this.playerInstance.deregisterEvent("contentended");
    }

    eventBus.$off("check-for-series-route");
    eventBus.$off("send-episode-list");
    eventBus.$off("check-new-fire-data");
  },
  components: {
    contentProgress: () =>
      import(
        /* webpackchunkname: contentprogress */ "@/components/Templates/contentProgress.vue"
      ),
    continueMerge: () =>
      import(
        /* webpackchunkname: contentmerge */ "@/components/categorySlider/continueMerge.vue"
      ),
    continueWatchingPlaceholder: () =>
      import(
        /* webpackchunkname: continueWatchingPlaceholder */ "@/components/placeholders/continueWatchingPlaceholder.vue"
      ),
  },
  mixins: [Utility, FirebaseActions],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "./continueSlider.scss"
</style>
